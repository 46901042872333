import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import logo from "../../assets/images/aishacontent/aishalogo.png"
// import logo from "../../assets/images/obacoderslogo2.png"

const NavbarTwo = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area navbar-style-two">
                <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img src={logo} alt="image" />
                                {/* <img src="https://firebasestorage.googleapis.com/v0/b/obacodersbrochurewebsite.appspot.com/o/obacoderslogo2.png?alt=media&token=25ebcbdb-106f-4d30-8ca7-167f5fb563be" alt="logo" /> */}
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item">
                                        <AnchorLink
                                            to="/#home"
                                            title="Home"
                                            className="nav-link"
                                            stripHash
                                        />
                                    </li>

                                    <li className="nav-item">
                                        <AnchorLink
                                            to="/#oursolutions"
                                            title="Our Solutions"
                                            className="nav-link"
                                            stripHash
                                        />
                                    </li>

                                    <li className="nav-item">
                                        <AnchorLink
                                            to="/#aboutus"
                                            title="About Us"
                                            className="nav-link"
                                            stripHash
                                        />
                                    </li>

                                    <li className="nav-item">
                                        <AnchorLink
                                            to="/#howitworks"
                                            title="How It Works"
                                            className="nav-link"
                                            stripHash
                                        />
                                    </li>

                                    <li className="nav-item">
                                        <AnchorLink
                                            to="/#ourblog"
                                            title="Our Blog"
                                            className="nav-link"
                                            stripHash
                                        />
                                    </li>

                                    {/* <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Home
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            About us
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Services
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Testimonials
                                        </Link>
                                    </li> */}

                                    {/* <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Contact us
                                        </Link>
                                    </li> */}
                                </ul>

                                {/* <li className="nav-item">
                                    <AnchorLink
                                        to="/machine-learning-ai-solutions#contactus"
                                        title="Contact Us"
                                        className="nav-link"
                                        stripHash
                                    />
                                </li> */}

                                <div className="others-option d-flex align-items-center navbar-nav">
                                    <div className="option-item nav-item">
                                        <AnchorLink
                                            to="/#contactus"
                                            title="Contact Us"
                                            className="nav-link"
                                            stripHash
                                        />
                                        {/* <Link
                                            to="/contact"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i>
                                            Contact Us<span></span>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NavbarTwo
