import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>AISHA Care - Artificial Intelligence Solutions for Healthcare Applications</title>
                <meta name="description" content="AISHA Care - Artificial Intelligence Solutions for Healthcare Applications" />
                <meta name="og:title" property="og:title" content="AISHA Care - Artificial Intelligence Solutions for Healthcare Applications"></meta>
                <meta name="twitter:card" content="AISHA Care - Artificial Intelligence Solutions for Healthcare Applications"></meta>
                <link rel="canonical" href="https://aisha.care/"></link>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/gym-pal-b09bf.appspot.com/o/aishascreenshotwebpreview.png?alt=media&token=574936d9-ad8c-478b-b428-fba8db92e456" />
            </Helmet>
        </div>
    )
}

export default SEO
